<template>
    <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/yallagame.png" style="height: 50px;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3 pop-outin" style="color: #fff;">شركة الاتصالات السعودية</h5>
                 <h5 class="font-weight-bold mt-3" style="color: #fff;">للاشتراك الرجاء ارسال الرقم 3 في رسالة نصية الى 802043</h5>
              </div>
              <form>
                <div class="form-group">
                  <!-- <label class="mb-1" style="color: #fff;">رقم الهاتف</label> -->
                    <div class="position-relative icon-form-control">
                        <p class="font-weight-bold mt-3" style="color: #fff;font-size: 14px;padding-top: 4vh;">هذه الخدمة متوفرة لعملاء شركة الاتصالات السعودية مقابل 1.5 تتجدد يوميا (غير شامل قيمة الضريبة المضافة 15%) .</p>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'AppSTC',
}
</script>
<style scoped>
.btn-warning {
  color: #fff;
  border-color: rgb(131, 203, 206);
  background: #315b10 none repeat scroll 0% 0% !important
}

.bg-dark {
  background-image: linear-gradient(0deg,#315b10 0,#67bb27 );
}


</style>